import { Box, Button, Paper, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbHeader from "../../../src/components/Marketplace/viewProducts/BreadCrumbHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import print from "../../../src/assets1/Images/CommonImages/PrintInvoice.svg";
import download from "../../../src/assets1/Images/CommonImages/downloadinvoice.svg";
import LeftArrow from "../../../src/assets1/Images/CommonImages/Left Arrow.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import axios from "axios";

const CreditTerms = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ProductData = location?.state?.ProductData;
  const CouponData = location?.state?.CouponData;
  const CreditedAmount = location?.state?.CreditedAmount;
  const ItemData = location?.state?.Itemdata;
  const [UserData, setUserData] = useState();

  let AgreementData = CouponData?.Agreements?.filter(
    (item) => item?._order_number === CouponData?.OrderId
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(
        `/company/get_company/${ProductData?.companyId}`,
        {
          withCredentials: true,
        }
      );
      // response.data;
      setUserData(response.data);
    } catch (error) {}
  };

  const handleDownload = () => {
    setTimeout(() => {
      html2canvas(componentRef.current)
        .then((canvas) => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );
          pdf.save("CreditLine-Agreement.pdf");
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    }, 1000);
  };

  function getDate() {
    const updatedAt = AgreementData?.at(0)?._signed_date
      ? AgreementData?.at(0)?._signed_date
      : ItemData?.at(0)?._updated_at;
    if (updatedAt) {
      const date = new Date(updatedAt);
      const month = date.toLocaleString("default", { month: "long" });
      const day = date.getDate();

      const year = date.getFullYear();
      return `${day} of ${month}, ${year}`;
    } else {
      return "No Date"; // Handle the case when _updated_at is not present
    }
  }

  useEffect(() => {
    GetCompanyByID();
  }, []);

  const handleBack = () => {
    const stateToSend = {
      queryType: CouponData ? "" : "CreditApproved",
    };

    const navigation = CouponData
      ? "/admindashboard/addcouponcode"
      : `/admindashboard/allproductapprovals/${ProductData?.companyId}`;

    navigate(navigation, { state: stateToSend });
  };

  return (
    <Box
      sx={{
        height: "620px",
        overflowY: "scroll",
      }}
    >
      <Box
        component="img"
        src={LeftArrow}
        sx={{ width: "25px", cursor: "pointer" }}
        onClick={() => handleBack()}
      />
      <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
        <Paper
          ref={componentRef}
          sx={{
            bgcolor: "#fff",
            boxShadow: "none",
            p: 3,
            borderRadius: "20px",
            height: "auto",
            minHeight: "520px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            //   alignItems: "center",
            //   bgcolor: "red",
            gap: "30px",
          }}
          elevation={0}
        >
          <Box
            sx={{
              width: "95%",
              height: "auto",
              minHeight: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "20px",
                color: "#7D8BA6",
              }}
            >
              <b>
                <u>CREDIT LINE AGREEMENT FOR THE BARTER COINS</u>
              </b>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              overflowY: "scroll",
              gap: "30px",
              p: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
                // mx: "auto",
                my: 2,
              }}
            >
              <Typography sx={credittermstext}>
                THIS CREDIT LINE AGREEMENT FOR BARTER COINS <b>(“Agreement”)</b>{" "}
                is made and entered into effective as of the{" "}
                <b style={{ borderBottom: "dotted" }}> {getDate()} </b>{" "}
                <b>(“Effective Date”)</b> by and among the following parties:{" "}
                <br /> <b>BXI WORLD LLP (Lender)</b> has its Corporate Office at
                501-5 th Floor Meadows Tower, Sahara Plaza Complex, Sir M.V.
                Road, Next to Kohinoor Continental Hotel, Andheri (East);
                Mumbai-400059 (hereinafter referred to as BXI).
              </Typography>
              <Typography sx={{ ...credittermstext, textAlign: "center" }}>
                <b>AND</b>
              </Typography>
              <Typography sx={credittermstext}>
                <b style={{ borderBottom: "dotted" }}>
                  {UserData?.companyName ||
                    CouponData?.buyerCompanyData?.companyName}
                </b>{" "}
                and Registered Address{" "}
                <b style={{ borderBottom: "dotted" }}>
                  {UserData?.CompanyAddress?.AddressLine ||
                    CouponData?.buyerCompanyData?.CompanyAddress?.AddressLine}
                </b>
                . (Hereinafter referred to as Borrower).
              </Typography>
              <Typography sx={credittermstext}>
                <b>WHEREAS, </b>the Borrower is engaged in the business of
                ________ and desires to offer its Product and/or Services
                ____________ against Credit Line at BXI Marketplace.
              </Typography>
              <Typography sx={credittermstext}>
                <b>WHEREAS, </b>
                the Borrower receives Barter Coins valued according to the Total
                value as determined by BXI in the table below.
              </Typography>
              <Typography sx={credittermstext}>
                <b>WHEREAS, </b>
                the BXI and Borrower have agreed to enter into this Agreement
                upon their free will and consent and upon conditions hereinafter
                appearing as per the following terms and conditions:
              </Typography>
              <Typography sx={{ ...credittermstext, mt: 2 }}>
                Details of the product against which credit line is issued are
                as below.
              </Typography>

              <TableContainer
                sx={{
                  mx: "auto",
                  width: "90%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={table}>Sr. No</TableCell>
                      <TableCell sx={table}>
                        Product Name & Description
                      </TableCell>
                      <TableCell sx={table}>Product ID</TableCell>
                      <TableCell sx={table}> Quantity </TableCell>
                      <TableCell sx={table}>Rate for BXI</TableCell>
                      <TableCell sx={table}>Total Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={table}>1.</TableCell>
                      <TableCell sx={table}>
                        {ProductData?.productId?.ProductName ||
                          AgreementData?.at(0)?._readyStock_Product
                            ?.ProductName}
                      </TableCell>
                      <TableCell sx={table}>
                        {AgreementData?.at(
                          0
                        )?._readyStock_Product?.ProductsVariantions?.at(0)
                          ?.ProductIdType ? (
                          AgreementData?.at(
                            0
                          )?._readyStock_Product?.ProductsVariantions?.at(0)
                            ?.ProductIdType
                        ) : (
                          <>
                            {ProductData?.productId?.ProductsVariantions?.at(0)
                              ?.ProductIdType
                              ? ProductData?.productId?.ProductsVariantions?.at(
                                  0
                                )?.ProductIdType
                              : "--"}
                          </>
                        )}
                      </TableCell>
                      <TableCell sx={table}>
                        {ItemData?.at(0)?._quantity_for_credit || "--"}
                      </TableCell>
                      <TableCell sx={table}>
                        {ItemData?.at(0)?._bxirate_for_credit || "--"}
                      </TableCell>
                      <TableCell sx={table}>
                        {CouponData?.TokenAmount
                          ? CouponData?.TokenAmount
                          : CreditedAmount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "98%",
                  ml: "auto",
                }}
              >
                <Typography sx={credittermstext}>
                  <b> 1. Purpose of the Credit Line:</b> General Credit Line:
                  The Borrower wishes to utilize a Credit Line to purchase
                  Products and/or Services from the BXI Marketplace. BXI has
                  assessed the value of the Borrower's offered Products and/or
                  Services as collateral for this Credit Line. By accepting this
                  Agreement, the Borrower confirms and agrees to receive Barter
                  Coins on the Credit Line, subject to the outlined terms and
                  conditions.
                  <Typography sx={{ ...credittermstext, textAlign: "left" }}>
                    <b>OR</b>
                  </Typography>
                  Conditional Credit Line: The Borrower/Buyer wishes to utilize
                  Coupon Code to purchase a specific product from the BXI
                  Marketplace. BXI has issued a Coupon Code that specifies the
                  amount of the conditional credit line and product for which it
                  is applicable. By accepting this Agreement, the Borrower/Buyer
                  confirms and agrees to receive Coupon Code of the specific
                  amount, subject to the outlined terms and conditions.
                </Typography>
                <Typography sx={credittermstext}>
                  {" "}
                  <b> 2. Interest:</b> BXI does not impose any interest on
                  Barter Coins issued to the Borrower. Barter Coins are
                  interest-free credits, credited to the Borrower’s wallet on
                  providing the Products and/or Services as security.
                </Typography>
                <Typography sx={credittermstext}>
                  {" "}
                  <b> 3. Validity of Barter Coins:</b> Barter Coins provided
                  through this Credit Line remain valid as long as the Borrower
                  remains active on the BXI platform. These coins are
                  exclusively usable within the BXI ecosystem and cannot be
                  employed as legal tender elsewhere. The Borrower must utilize
                  the Barter Coins before leaving the platform. Failure to
                  utilize all Barter Coins means the Borrower forfeits any
                  entitlement to repayment in INR for the remaining value of the
                  coins.
                </Typography>
                <Typography sx={credittermstext}>
                  {" "}
                  <b> 4. Rights of Lender: </b>BXI retains the authority over
                  the products and services listed on the BXI platform by the
                  Borrower, against which a Credit Line has been allocated.
                  These listed products and services must remain inaccessible
                  for removal from the marketplace until all associated
                  obligations have been met by the respective members.
                </Typography>
                <Typography sx={credittermstext}>
                  {" "}
                  <b> 5. Ready Stock: </b>The Products and/or Services covered
                  by the Credit Line must be labeled as “Ready Stock” and have a
                  minimum 75% shelf life upon delivery. They must remain listed
                  on the Marketplace until the Credit Line is repaid. The
                  Borrower must list identical Products and/or Services without
                  discrepancies in quality, quantity, rate, or merchantability.
                  If inventory is exhausted, BXI may select alternative Products
                  to recover the Credit Line. Failure to sell or dispatch
                  designated Products constitutes an Event of Default.
                </Typography>
                <Typography sx={credittermstext}>
                  {" "}
                  <b> 6. Use of Barter Coins:</b> That Borrower shall use these
                  Barter Coins to purchase products and services available on
                  the marketplace. The borrower is aware that Barter coins are
                  not Reward Points; the same cannot be transferred to other
                  members of the Platform without a transaction and Barter coins
                  cannot be used to pay GST, BXI fees, and other charges. (Note:
                  GST, BXI fees, and other charges shall be paid in Indian
                  Currency INR)
                </Typography>
                <Typography sx={credittermstext}>
                  {" "}
                  <b> 7. Repayment of Barter Coins:</b> Members shall fulfil
                  their obligation to repay their Barter Coins by engaging in
                  the sale of products and services on the BXI Marketplace,
                  which served as the basis for the issuance of their Credit
                  Line.
                </Typography>
                <Typography sx={credittermstext}>
                  <b> 8. Security:</b> Members can request a Barter Credit line.
                  This Credit Line will be issued against the products and
                  services which will be BXI’s security. The same products and
                  services shall be listed by Borrower at the BXI Marketplace
                  for sale to the interested Members.
                </Typography>
                <Typography sx={credittermstext}>
                  <b> 9. Events of Default:</b> <br />
                  The following events shall constitute events of default under
                  the Credit Line: <br /> <br />
                  <Typography
                    sx={{ ...credittermstext, width: "97%", ml: "auto" }}
                  >
                    <li>
                      {" "}
                      Breach by the borrower of any other provision of the
                      credit line that is not capable of remedy, or that is
                      capable of remedy but is not remedied by the borrower
                      immediately.{" "}
                    </li>
                    <br /> <br />{" "}
                    <li>
                      {" "}
                      That if the sample of products and services are different
                      from the delivered products and services.
                    </li>
                    <br /> <br />{" "}
                    <li>
                      {" "}
                      That if the quantity, quality and features of products and
                      services are not similar as per the description of the
                      same.
                    </li>
                    <br /> <br />{" "}
                    <li>
                      {" "}
                      That the products and services should not be near to
                      shelf-life and allotted products and services shall be
                      from your Fresh stock.
                    </li>
                    <br /> <br />{" "}
                    <li>
                      {" "}
                      That if the products and services are not delivered during
                      the promised timelines.
                    </li>
                    <br /> <br />{" "}
                    <li>
                      {" "}
                      That products and services can be availed by BXI at any
                      time within 18 months from the date of execution of this
                      Agreement.
                    </li>
                    <br /> <br />{" "}
                    <li>
                      {" "}
                      That no hidden charges shall be disclosed by the borrower
                      at the time of delivery of products and services.
                    </li>
                  </Typography>
                </Typography>
                {/* <Typography sx={credittermstext}>
                <b> 9. Termination of Association:</b> No Borrower shall be
                permitted to terminate their membership on the BXI platform
                until they have fulfilled their obligations as mentioned herein.
                If a member fails to deliver the product and/or service to the
                purchasing member in accordance with the agreed terms stated in
                the Agreement, the Borrower shall be held fully liable for the
                payment of the equivalent value of the goods and services in
                Indian Rupees INR. The borrower’sliability shall be enforceable
                regardless of any circumstances that may have hindered the
                product and services delivery, subject to any force majeure.{" "}
              </Typography> */}
                <Typography sx={credittermstext}>
                  <b> 10. Remedies on Default:</b> Upon the occurrence of an
                  event of default, the lender shall be entitled to exercise any
                  remedies available to it under Indian law or may take any
                  other action necessary to protect its interest I.e., Immediate
                  termination of BXI membership.{" "}
                </Typography>
                <Typography sx={credittermstext}>
                  <b> 11. Governing Law and Jurisdiction:</b> This Credit Line
                  Agreement for Barter Coins shall be governed by and construed
                  in accordance with the laws of India. Any dispute arising out
                  of or in connection with this agreement shall be submitted to
                  the exclusive jurisdiction of the Courts in Mumbai.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              ...credittermstext,
              display: "flex",
              justifyContent: "center",
              gap: "3px",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {/* <DoneAllIcon
                sx={{
                  fontSize: "18px",
                  color: "#445fd2",
                }}
              /> */}
            {/* Agreed */}
            Approved by{" "}
            {UserData?.companyName || CouponData?.buyerCompanyData?.companyName}
          </Typography>
        </Paper>
        <Box
          sx={{
            width: "100%",
            mt: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              maxWidth: "360px",
              mx: "auto",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            <Button variant="outlined" sx={btnsx} onClick={handlePrint}>
              <Box component={"img"} src={print} sx={ButtonIconStyle} />{" "}
              &nbsp;Print
            </Button>

            <Button
              id="download-button"
              variant="outlined"
              sx={btnsx}
              onClick={handlePrint}
            >
              <Box component={"img"} src={download} sx={ButtonIconStyle} />{" "}
              &nbsp;Download
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreditTerms;

const reqbtn = {
  width: "157px",
  height: "57px",
  boxShadow: "none",
  background: "#445FD2",
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "24px",
  textTransform: "none",
};

const credittermstext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "13px",
    lg: "13px",
    md: "10px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "justify",
  color: "#7D8BA6",
};

const table = {
  borderLeft: "1px solid #7D8BA6",
  borderRight: "1px solid #7D8BA6",
  borderBottom: "1px solid #7D8BA6",
  borderTop: "1px solid #7D8BA6",
  textAlign: "center",
  fontFamily: "Poppins",
  color: "#7D8BA6",
  fontSize: "12px",
};

const btnsx = {
  width: "100%",
  height: "51px",
  maxWidth: "168px",
  borderRadius: "10px",
  borderColor: "#156DB6",
  color: "#156DB6",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const ButtonIconStyle = {
  width: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  height: "auto",
};
