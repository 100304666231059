import React, { useEffect, useState } from "react";
import MembershipInvoice from "./Invoices/MembershipInvoice";
import BXICommission from "./Invoices/BXICommission";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ViewInvoice = () => {
  const { id, type } = useParams();
  const location = useLocation();
  const OrderData = location?.state?.OrderData;
  const [storeData, setStoredata] = useState({});
  const [membershipStoreData, setMembershipStoreData] = useState({});

  useEffect(() => {
    const CommissionLocalIRNData = JSON.parse(
      localStorage.getItem("CommissionIRNData")
    );
    const MembershipLocalIRNData = JSON.parse(
      localStorage.getItem("MembershipIRNData")
    );
    setStoredata({
      Data: MembershipLocalIRNData?.Data,
      QR: MembershipLocalIRNData?.IRNData,
      type: MembershipLocalIRNData?.IRNType,
      id: MembershipLocalIRNData?.IRNId,
      IRNAckNo: MembershipLocalIRNData?.IRNAckNo,
      IRNAckDate: MembershipLocalIRNData?.IRNAckDate,
    });

    setMembershipStoreData({
      QR: CommissionLocalIRNData?.IRNData,
      type: CommissionLocalIRNData?.IRNType,
      id: CommissionLocalIRNData?.IRNId,
      IRNAckNo: CommissionLocalIRNData?.IRNAckNo,
      IRNAckDate: CommissionLocalIRNData?.IRNAckDate,
    });
  }, []);

  return type === "mem" ? (
    <MembershipInvoice
      Data={storeData?.Data}
      id={id}
      qr={storeData?.QR}
      irn={storeData?.id}
      IRNAckNo={storeData?.IRNAckNo}
      IRNAckDate={storeData?.IRNAckDate}
    />
  ) : type === "com" ? (
    <BXICommission
      OrderData={OrderData}
      id={id}
      qr={membershipStoreData?.QR}
      irn={membershipStoreData?.id}
      IRNAckNo={membershipStoreData?.IRNAckNo}
      IRNAckDate={membershipStoreData?.IRNAckDate}
    />
  ) : null;
};

export default ViewInvoice;
