import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  Modal,
  Button,
  Tabs,
  Tab,
  Divider,
  Stack,
  IconButton,
  Dialog,
} from "@mui/material";
import BXICOIN from "../../assets/GenieImg/BXICOIN.png";
import * as Styles from "../../common/Styles";
import InputBase from "@mui/material/InputBase";
import axios from "axios";
import { styled, alpha } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { GetTokenFunc } from "../../utils/GetToken";
import { Delete, Edit } from "@mui/icons-material";
import CommaSeprator from "../../common/CommaSeparator";
import CompanyTypeComp from "./CompanyTypeComp";
import Searchbar from "../../common/Searchbar";
import CloseIconDialog from "@mui/icons-material/Close";
import { useFetchWalletBalance } from "../../Hooks/WalletData/useGetWalletData";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { HiDownload } from "react-icons/hi";
import * as XLSX from "xlsx/xlsx.mjs";

const Creditline = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [allCredit, setAllCredit] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchData, setSearchData] = useState("");
  const [Loader, setLoader] = useState(false);
  const [CreditData, setCreditData] = useState();
  const [viewComapny, setViewComapny] = useState(false);
  const [companyId, setcompanyId] = useState("company");
  const itemsPerPage = 10;

  const handleViewClose = () => setViewComapny(false);
  const handleViewOpen = () => setViewComapny(true);

  const handleDateRangeChange = (newDates) => {
    // Convert JavaScript Date objects to dayjs objects
    const newStartDate = dayjs(newDates[0]);
    const newEndDate = dayjs(newDates[1]);

    // Update state with the new dates
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const totalItems = allCredit?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginatedData = () => {
    const reversedData = allCredit?.slice().reverse();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return reversedData?.slice(startIndex, endIndex);
  };

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const AllCreditDetails = async () => {
    setLoader(true);
    await axios
      .get(
        `wallet_funds/get-all-requests?search=${searchData}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        setLoader(false);
        setAllCredit(res?.data);
      })
      .catch((err) => {});
  };

  const fetchtransaction = async () => {
    try {
      await axios.get(`wallet/get_all_credit_line_transaction`).then((res) => {
        setCreditData(res?.data?.body);
      });
    } catch (error) {}
  };

  useEffect(() => {
    AllCreditDetails();
    fetchtransaction();
  }, [searchData, endDate]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  const calculateSums = (productVariations) => {
    let totalValueSum = 0;
    let maxOrderQtySum = 0;

    productVariations?.forEach((item) => {
      const currentValue = item?.MaxOrderQuantity * item?.DiscountedPrice;

      totalValueSum += currentValue;
      maxOrderQtySum += item?.MaxOrderQuantity;
    });

    return {
      totalValueSum,
      maxOrderQtySum,
    };
  };

  const [creditLineDetails, setCreditLineDetails] = useState([]);
  const [Recoverybalance, setRecoverybalance] = useState();

  const GetAllCreditDetails = async () => {
    const Wallet_Url = "https://wallet-api.development-bxi.unada.in";

    await axios
      .get(`${Wallet_Url}/api/v1/chart/GetAllBxiCreditDetails`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setCreditLineDetails(res.data);
      })
      .catch((err) => {});
  };

  const GetCreditlinerecoverybalance = async () => {
    try {
      const response = await axios.get(
        `wallet/get_company_wallet_recovery_balance/${companyId?.companyId}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      );
      setRecoverybalance(response.data.body.totalBalance);
    } catch (err) {
      console.error("Error fetching recovery balance:", err);
    }
  };

  const {
    data: totalBalance,
    isError: balanceError,
    isLoading: balanceLoading,
  } = useFetchWalletBalance(companyId?.companyId);

  const creditAcceptData = creditLineDetails?.creditAcceptData
    ?.filter((product) => product?._to_wallet_id === companyId?.companyId)
    .reduce((acc, product) => {
      return acc + (product?._balance || 0);
    }, 0);

  const creditRecoverData = creditLineDetails?.recoveryData
    ?.filter((product) => product?._to_wallet_id === companyId?.companyId)
    .reduce((acc, product) => {
      return acc + (product?._balance || 0);
    }, 0);

  useEffect(() => {
    GetAllCreditDetails();
    GetCreditlinerecoverybalance();
  }, [companyId]);

  const ProductDataRender = () => {
    return (
      <Paper elevation={0}>
        {getPaginatedData()?.length <= 0 ? (
          <Box sx={setBox}>
            <img
              src={recyclebin}
              alt="recycle"
              style={{ height: "auto", width: "23%" }}
            />
            <Typography sx={empty}>Data is empty</Typography>
          </Box>
        ) : Loader ? (
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              pl: "45%",
            }}
          >
            <PageLoader />
          </Box>
        ) : (
          <Box mt={2} sx={{ background: "#FAFBFC", p: 0.5 }}>
            <Grid
              container
              sx={{
                p: 1,
                textAlign: "center",
              }}
            >
              <Grid
                item
                xl={0.5}
                lg={0.5}
                md={0.5}
                sm={0.5}
                xs={0.5}
                sx={{
                  ...Styles.textHead,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                No.
              </Grid>
              <Grid
                item
                xl={2.5}
                lg={2.5}
                md={2.5}
                sm={2.5}
                xs={2.5}
                sx={{
                  ...Styles.textHead,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                Product Name
              </Grid>
              <Grid
                item
                xl={2.5}
                lg={2.5}
                md={2.5}
                sm={2.5}
                xs={2.5}
                sx={Styles.textHead}
              >
                Company Name
              </Grid>
              {/* <Grid
                item
                xl={0.7}
                lg={0.7}
                md={0.7}
                sm={0.7}
                xs={0.7}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Category
              </Grid>

              <Grid
                item
                xl={0.7}
                lg={0.7}
                md={0.7}
                sm={0.7}
                xs={0.7}
                sx={Styles.textHead}
              >
                Type
              </Grid> */}
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Requested Amount
              </Grid>

              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Eligible Amount
              </Grid>

              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Approved Amount
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Issued Date
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={Styles.textHead}
              >
                Expiry Date
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                sx={{
                  ...Styles.textHead,
                }}
              >
                Status
              </Grid>
              <Grid
                item
                xl={0.5}
                lg={0.5}
                md={0.5}
                sm={0.5}
                xs={0.5}
                sx={Styles.textHead}
              >
                Edit
              </Grid>
            </Grid>

            {getPaginatedData()?.map((item, idx) => {
              const continuousIdx = (currentPage - 1) * itemsPerPage + idx + 1;

              const credittoken =
                (Number(item?.totalPoints + item?.productId?.ManualPoint) /
                  120) *
                100;

              const sums = calculateSums(item?.productId?.ProductsVariantions);

              const highestValue = sums?.totalValueSum;

              const totalValue = ((highestValue * credittoken) / 100).toFixed();

              // const CreditDataFilter = CreditData?.filter(
              //   (item) => item?._user_id === paramId
              // );

              const productData = CreditData?.filter(
                (product) => product?._request_id?.toString() === item?._id
              );

              return (
                <Grid container sx={Styles.containerStyles}>
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    align="center"
                    className="product-title-colum"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {continuousIdx}
                  </Grid>
                  <Grid
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    align="left"
                    className="product-title-colum"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        color: "#78778B",
                      }}
                    >
                      <Box
                        sx={{
                          width: "auto",
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        {item?.productId?.ProductImages?.at(0)?.url ? (
                          <>
                            <img
                              src={item?.productId?.ProductImages?.at(0)?.url}
                              alt="company-logo"
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "30px",
                              }}
                            />{" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#78778B",
                              }}
                            >
                              {item?.productId?.ProductName
                                ? item?.productId?.ProductName
                                : "--"}

                              <br />
                              {item?.clnumber ? (
                                <span
                                  style={{
                                    fontSize: "9px",
                                  }}
                                >
                                  Credit_Id: {item?.clnumber}
                                </span>
                              ) : (
                                "--"
                              )}
                            </Typography>
                          </>
                        ) : item?.productId?.VoucherImages?.at(0)?.url ? (
                          <>
                            <img
                              src={item?.productId?.VoucherImages?.at(0)?.url}
                              alt="company-logo"
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "30px",
                              }}
                            />{" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#78778B",
                              }}
                            >
                              {item?.productId?.ProductName
                                ? item?.productId?.ProductName
                                : "--"}
                              <br />
                              {item?.clnumber ? (
                                <span
                                  style={{
                                    fontSize: "9px",
                                  }}
                                >
                                  Credit_Id: {item?.clnumber}
                                </span>
                              ) : (
                                "--"
                              )}
                            </Typography>
                          </>
                        ) : (
                          "--"
                        )}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    sx={Styles.subTextData}
                  >
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setcompanyId(item);
                        handleViewOpen();
                      }}
                    >
                      {item?.productId?.SellerCompanyName
                        ? item?.productId?.SellerCompanyName
                        : "--"}
                      <br />
                      <CompanyTypeComp
                        companyTypeId={item?.productId?.ProductType}
                      />
                    </span>
                  </Grid>

                  {/* <Grid
                    item
                    xl={0.7}
                    lg={0.7}
                    md={0.7}
                    sm={0.7}
                    xs={0.7}
                    sx={Styles.subTextData}
                  >
                    <CompanyTypeComp
                      companyTypeId={item?.productId?.ProductType}
                    />
                  </Grid> */}
                  {/* <Grid
                    item
                    xl={0.7}
                    lg={0.7}
                    md={0.7}
                    sm={0.7}
                    xs={0.7}
                    sx={Styles.subTextData}
                  >
                    <span>
                      {item?.productId?.ListingType
                        ? item?.productId?.ListingType
                        : "--"}
                    </span>
                  </Grid> */}
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    <span>
                      {item?.requestedUserAmount ? (
                        <CommaSeprator Price={item?.requestedUserAmount} />
                      ) : (
                        "0.00"
                      )}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {item?.ProductType === "Media" ? (
                      "0.00"
                    ) : (
                      <span>
                        <CommaSeprator Price={totalValue} />
                      </span>
                    )}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {item?.approvedAmount ? (
                      <CommaSeprator Price={item?.approvedAmount} />
                    ) : (
                      "0.00"
                    )}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {item?.creditLineTransaction?._document_type === "onhold" ||
                    item?.creditLineTransaction?._document_type ===
                      "transaction" ? (
                      <Box>
                        {item?.creditLineTransaction?._status === true ? (
                          <span>
                            {item?.creditLineTransaction?.createdAt ===
                            item?.creditLineTransaction?.updatedAt
                              ? ""
                              : formatDate(
                                  item?.creditLineTransaction?.updatedAt
                                )}
                          </span>
                        ) : null}
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.subTextData}
                  >
                    {item?.creditLineTransaction?._document_type === "onhold" ||
                    item?.creditLineTransaction?._document_type ===
                      "transaction" ? (
                      <Box key={idx}>
                        {item?.creditLineTransaction?._acceptorreject ===
                        "accept" ? (
                          <span>
                            {item?.creditLineTransaction?.createdAt ===
                            item?.creditLineTransaction?.updatedAt
                              ? ""
                              : formatDate(
                                  new Date(
                                    new Date(
                                      item?.creditLineTransaction?.updatedAt
                                    ).setDate(
                                      new Date(
                                        item?.creditLineTransaction?.updatedAt
                                      ).getDate() + 365
                                    )
                                  )
                                )}
                          </span>
                        ) : (
                          ""
                        )}
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={{
                      ...Styles.subTextData,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "11px",
                        color: "#445fd2",
                        background: "#445fd221",
                        width: "100px",
                        height: "30px",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {item?.requestStatus === "accepted"
                          ? "BXI Accepted"
                          : item?.requestStatus === "pending"
                          ? "Request Raised"
                          : item?.requestStatus === "rejected"
                          ? "BXI Rejected"
                          : null}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={Styles.subTextData}
                  >
                    <IconButton
                      aria-label="edit"
                      style={{ color: "rgba(68, 95, 210, 1)" }}
                      onClick={() => {
                        // handleEditButtonClick(x._id);
                        // setId(x._id);
                        navigate(
                          `/admindashboard/allproductapprovals/${item?.companyId}`,
                          {
                            state: {
                              Index: "CreditRequest",
                              Navigation: true,
                            },
                          }
                        );
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}
      </Paper>
    );
  };

  const GeneratePDFStatement = () => {
    let ExcelData = allCredit?.slice().reverse();

    const generatePDFStatement = async () => {
      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.json_to_sheet(ExcelData);

      const headerRows = [
        [
          "Sr No",
          "Product Name",
          "Company Name",
          "Company Type Name",
          "Credit Id",
          "Requested Amount",
          "Eligible Amount",
          "Approved Amount",
          "Issued Date",
          "Expiry Date",
          "Status",
          "User Status",
        ],
      ];

      const infoWs = XLSX.utils.aoa_to_sheet(headerRows);

      await Promise.all(
        ExcelData?.map(async (item, index) => {
          const credittoken =
            (Number(item?.totalPoints + item?.productId?.ManualPoint) / 120) *
            100;

          const sums = calculateSums(item?.productId?.ProductsVariantions);

          const highestValue = sums?.totalValueSum;

          const totalValue = ((highestValue * credittoken) / 100)?.toFixed(2);
          const rowData = [
            // srno
            index + 1,
            // Product name
            item?.productId?.ProductName ? item?.productId?.ProductName : "--",
            // Company name
            item?.productId?.SellerCompanyName
              ? item?.productId?.SellerCompanyName
              : "--",
            // Company Type
            <CompanyTypeComp companyTypeId={item?.productId?.ProductType} />,
            // Creditline number
            item?.clnumber,
            // Requested Amount
            item?.requestedUserAmount
              ? Number(item?.requestedUserAmount)?.toFixed(2)
              : "0.00",
            // Eligible Amount
            item?.ProductType === "Media" ? "0.00" : totalValue,
            // Approved Amount
            item?.approvedAmount ? item?.approvedAmount?.toFixed(2) : "0.00",
            // Issued Date
            item?.creditLineTransaction?._document_type === "onhold" ||
            item?.creditLineTransaction?._document_type === "transaction"
              ? item?.creditLineTransaction?._status === true
                ? item?.creditLineTransaction?.createdAt ===
                  item?.creditLineTransaction?.updatedAt
                  ? ""
                  : new Date(item?.creditLineTransaction?.updatedAt)
                : null
              : null,
            // Expiry Date
            item?.creditLineTransaction?._document_type === "onhold" ||
            item?.creditLineTransaction?._document_type === "transaction"
              ? item?.creditLineTransaction?._acceptorreject === "accept"
                ? item?.creditLineTransaction?.createdAt ===
                  item?.creditLineTransaction?.updatedAt
                  ? ""
                  : new Date(
                      new Date(item?.creditLineTransaction?.updatedAt).setDate(
                        new Date(
                          item?.creditLineTransaction?.updatedAt
                        ).getDate() + 365
                      )
                    )
                : ""
              : null,
            // Expiry Date
            item?.requestStatus === "accepted"
              ? "BXI Accepted"
              : item?.requestStatus === "pending"
              ? "Request Raised"
              : item?.requestStatus === "rejected"
              ? "BXI Rejected"
              : null,
            // User Status
            item?.creditLineTransaction?._document_type === "onhold" ||
            item?.creditLineTransaction?._document_type === "transaction"
              ? item?.creditLineTransaction?._acceptorreject === "reject"
                ? "Member Declined"
                : item?.creditLineTransaction?._acceptorreject === "accept"
                ? "Credit Issued"
                : "Member Pending"
              : "",
          ];

          XLSX.utils.sheet_add_aoa(infoWs, [rowData], { origin: index + 1 });
        })
      );

      XLSX.utils.book_append_sheet(wb, infoWs, "Credit line");

      XLSX.writeFile(wb, `Credit_line-${new Date().toISOString()}.xlsx`);
    };

    return (
      <Button
        onClick={generatePDFStatement}
        sx={{
          background: "#445FD2",
          borderRadius: "17px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "12px",
          textTransform: "none",
          color: "#F8F8F8",
          width: "150px",
          height: "40px",
          "&:hover": {
            background: "#445FD2",
          },
        }}
      >
        <HiDownload fill="white" size={20} /> Download Excel
      </Button>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "600px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box
        className="page-heading-section"
        style={{ justifyContent: "start", gap: "20px", alignItems: "center" }}
      >
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/admindashboard/allrecords");
          }}
        >
          <KeyboardBackspaceIcon />
        </Box>
        <Box className="page-heading">
          <h5>All Credit line</h5>
          <Typography>
            Click on Edit & You will be directed to the Application Approval
            Page
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        <Box
          className="products-filters-row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              {allCredit instanceof Object ? (
                <Typography sx={textBudge}>
                  Total : {allCredit?.length}
                </Typography>
              ) : (
                <Typography sx={textBudge}>Total Requests: 0</Typography>
              )}
            </Typography>
          </Box>
          <Searchbar onSendData={handleChildData} />
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                startText="Start-Date"
                endText="End-Date"
                value={[startDate, endDate]}
                onChange={handleDateRangeChange}
                renderInput={(startProps, endProps) => (
                  <>
                    <input style={{ background: "red" }} {...startProps} />
                    <input style={{ background: "red" }} {...endProps} />
                  </>
                )}
              />
            </LocalizationProvider>
          </Box>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <GeneratePDFStatement />
          </div>
        </Box>
        {/* {CreditlineLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 5,
              width: "100%",
              // mr: '150%',
              ml: "500px",
            }}
          >
            <PageLoader />
          </Box>
        ) : ( */}
        <Box>{ProductDataRender()}</Box>
        {/* )} */}
      </Box>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={viewComapny}
        onClose={handleViewClose}
      >
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CloseIconDialog
              sx={{
                color: "#667085",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleViewClose();
              }}
            />
          </Box>
          <Typography sx={{ ...textBudge, fontSize: 16, py: 0.5 }}>
            Company Name : {companyId?.productId?.SellerCompanyName}
          </Typography>
          <Typography sx={{ ...textBudge, fontSize: 16, py: 0.5 }}>
            Credit Issued :
            <span style={{ ...Styles.textHead, fontSize: "14px" }}>
              {" "}
              {creditAcceptData?.toFixed(2)}
            </span>
          </Typography>

          <Typography sx={{ ...textBudge, fontSize: 16, py: 0.5 }}>
            Credit Due :
            <span style={{ ...Styles.textHead, fontSize: "14px" }}>
              {" "}
              {totalBalance?.data?.body?.creditDue?.toFixed(2)}
            </span>
          </Typography>

          <Typography sx={{ ...textBudge, fontSize: 16, py: 0.5 }}>
            Credits Recovered :
            <span style={{ ...Styles.textHead, fontSize: "14px" }}>
              {" "}
              {Recoverybalance
                ? (Recoverybalance)?.toFixed(2)
                : (creditAcceptData - totalBalance?.data?.body?.creditDue)?.toFixed(2)}
            </span>
          </Typography>
        </Box>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: {
            xl: "100%",
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "100%",
          },
          mx: "auto",
        }}
        mt={2}
      >
        <Stack>
          <StyledPagination
            size="medium"
            count={totalPages}
            color="primary"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default Creditline;

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};

const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const buddge = {
  display: "flex",
  height: "40px",
  width: "150px",
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
