import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Paper } from "@mui/material";
import TaxInvoice from "../AllRecords/Allinvoicedetail/TaxInvoice";
import MediaTaxInvoice from "../AllRecords/Allinvoicedetail/MediaTaxInvoice";
import VoucherTaxInvoice from "../AllRecords/Allinvoicedetail/VoucherTaxInvoice";
import MembershipFeesInvoice from "../AllRecords/Allinvoicedetail/MembershipFeesInvoice";
import MediaProformaInvoice from "../AllRecords/Allinvoicedetail/MediaProformaInvoice";
import ProformaInvoice from "../AllRecords/Allinvoicedetail/ProformaInvoice";
// import PurchaseOrder from "./Allinvoicedetail/Purchaseorder";
import BXICommission from "../AllRecords/Allinvoicedetail/BXICommission";
import "../Company/Company.Modules.css";
import BackArrowSmall from "../../assets/Vector (1).svg";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PurchaseOrder from "../AllRecords/InvoicesDetailPo";
import ProductOrderSummary from "./OrderSummaryPages/ProductOrderSummary";
import VoucherOrderSummary from "./OrderSummaryPages/VoucherOrderSummary";
import MediaOrderSummary from "./OrderSummaryPages/MediaOrderSummary";
// import axios from "axios";

const InvoiceDetail = () => {
  const location = useLocation();
  const Type = location?.state?.Type;

  const DataOfInvoice = location?.state?.DataOfInvoice;
  const tabvalue = location?.state?.tabvalue;
  const dataForChild = DataOfInvoice;

  const navigate = useNavigate();

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          onClick={() => {
            navigate(`/admindashboard/invoices`, {
              state: {
                tabvalue: tabvalue,
              },
            });
          }}
          component="img"
          src={BackArrowSmall}
          sx={{
            position: "relative",
            top: "35px",
            cursor: "pointer",
          }}
        />
        {Type === "Order" ? (
          dataForChild?.IsMedia ? (
            <MediaOrderSummary MediaSummaryData={dataForChild} />
          ) : dataForChild?.IsVoucher ||
            dataForChild?.ProductData?.at(0)?.VoucherType ||
            dataForChild?.OrderData?.IsVoucher ? (
            <VoucherOrderSummary VoucherSummaryData={dataForChild} />
          ) : (
            <ProductOrderSummary ProductSummaryData={dataForChild} />
          )
        ) : Type === "Proforma" ? (
          dataForChild?.IsMedia ? (
            <MediaProformaInvoice InvoiceDetailData={dataForChild} />
          ) : (
            <ProformaInvoice InvoiceDetailData={dataForChild} />
          )
        ) : // <ProformaInvoice dataFromParent={dataForChild} />
        Type === "TaxInvoice" ? (
          dataForChild?.IsMedia ? (
            <MediaTaxInvoice
              InvoiceDetailData={dataForChild}
              OrderData={dataForChild?.OrderData}
            />
          ) : dataForChild?.IsVoucher ||
            dataForChild?.OrderData?.IsVoucher ||
            dataForChild?.ProductData?.at(0)?.VoucherType ? (
            <VoucherTaxInvoice
              InvoiceDetailData={dataForChild}
              OrderData={dataForChild?.OrderData}
            />
          ) : (
            <TaxInvoice
              InvoiceDetailData={dataForChild}
              OrderData={dataForChild?.OrderData}
            />
          )
        ) : // <TaxInvoice dataFromParent={dataForChild} />
        DataOfInvoice?.POTotals && !dataForChild?.IsVoucher ? (
          <PurchaseOrder dataFromParent={dataForChild} />
        ) : // <PurchaseOrder dataFromParent={dataForChild} />
        DataOfInvoice?.Membership ? (
          <MembershipFeesInvoice dataFromParent={dataForChild} />
        ) : DataOfInvoice?.Commission ? (
          <BXICommission BXICommissionData={dataForChild} />
        ) : null}
      </Paper>
    </div>
  );
};

export default InvoiceDetail;
