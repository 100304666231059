import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../Company/Company.Modules.css";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { Delete, Edit } from "@mui/icons-material";
import CommaSeparator from "../../common/CommaSeparator";
import {
  Divider,
  IconButton,
  Modal,
  Typography,
  Stack,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import bxiToken from "../../assets/BXIToken.svg";
import FigmaIcon from "../../assets/figma-icon.png";
import * as Styles from "../../common/Styles";
import {
  useFetchWallet,
  useFetchWalletBalance,
  useFetchWalletSpent,
  useFetchWalletCredit,
  useFetchUSersEscrowBalance,
} from "../../Hooks/WalletData/useGetWalletData";
import { useRemoveWalletProduct } from "../../Hooks/WalletData/useRemoveWalletProduct";
import { useParams } from "react-router-dom";
import CommonDelete from "../CommonActions/CommonDelete";
import WalletEdit from "../CommonActions/WalletEdit";
import { GetTokenFunc } from "../../utils/GetToken";
import Wallettransaction from "./Wallettransaction";
const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "#1976d2",
  },
  // "& .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
  //   color: "#1976d2",
  //   display: "none",
  // },
  "& .MuiPaginationItem-root.Mui-selected": {
    color: "#fff",
  },
  "& .MuiPagination-ul li:last-child": {
    marginLeft: "16px",
  },
  "& .css-1v2lvtn-MuiPaginationItem-root": {
    marginRight: "16px",
    border: "none !important",
  },
  "& .MuiPagination-ul li:last-child": {
    marginLeft: "16px",
  },
  "& .MuiPagination-ul li:last-child button::before": {
    content: "'Last'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "11px",
    lineHeight: "14px",
    color: "#445FD2",
    marginRight: "8px",
  },
  "& .MuiPagination-ul li:first-child": {
    marginRight: "16px",
  },
  "& .MuiPagination-ul li:last-child button::before": {
    content: "'Last'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "11px",
    lineHeight: "14px",
    color: "#445FD2",
    marginRight: "8px",
  },
  "& .MuiPagination-ul li:first-child": {
    marginRight: "16px",
  },
  "& .MuiPagination-ul li:first-child button::after": {
    content: "'First'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "11px",
    lineHeight: "14px",
    color: "#445FD2",
    marginLeft: "8px",
  },
  "& .MuiPaginationItem-icon": {
    color: "#445FD2",
    fontWeight: 800,
  },
});
const Wallet = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const [activePage, setActivePage] = useState("All");
  const [ids, setIds] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [walletEditData, setWalletEditData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  let {
    data: WalletFundData,
    isLoading: walletFundDataLoading,
    isError: walletFundError,
    refetch: WalletRefetch,
  } = useFetchWallet(id, activePage, currentPage);
  useEffect(() => {
    if (WalletFundData && WalletFundData?.data?.TotalPages !== undefined) {
      setInitialPaginationCount(WalletFundData?.data?.TotalPages);
    }
  }, [WalletFundData?.data?.TotalPages]);
  const handleEditSave = (inputValues) => {
    //Edit logic here
    setWalletEditData(inputValues);
    setIsEditModalOpen(false);
    // setStoreIdForEdit(null);
  };

  const handleEditClose = () => {
    setIsEditModalOpen(false);
  };

  const {
    data: totalBalance,
    isError: balanceError,
    isLoading: balanceLoading,
  } = useFetchWalletBalance(id);

  const {
    data: totalSpent,
    isLoading: spentLoading,
    isError: spentError,
  } = useFetchWalletSpent(id);

  const {
    data: credit,
    isError: creditErrror,
    isLoading: creditLoading,
    refetch: creditRefetch,
  } = useFetchWalletCredit(id);
  //   delete
  const { data: mutateRemoveWalletData, mutate: removefromWallet } =
    useRemoveWalletProduct();
  const {
    data: EscrowBalance,
    isLoading: EscrowBalanceLoading,
    isError: EscrowBalanceError,
  } = useFetchUSersEscrowBalance(id);
  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    WalletRefetch();
    // Refetch data when activePage changes
  }, [activePage, currentPage]);

  const handleDelete = (id) => {
    removefromWallet(id, {
      onSuccess: (res) => {
        WalletRefetch();
        setIsDeleteModalOpen(false);
        setIds(null);
      },
      onError: (err) => {},
    });
  };
  const [creditLineDetails, setCreditLineDetails] = useState([]);
  const [Recoverybalance, setRecoverybalance] = useState();

  const GetAllCreditDetails = async () => {
    const Wallet_Url = "https://wallet-api.development-bxi.unada.in";

    await axios
      .get(`${Wallet_Url}/api/v1/chart/GetAllBxiCreditDetails`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setCreditLineDetails(res.data);
      })
      .catch((err) => {});
  };

  const GetCreditlinerecoverybalance = async () => {
    try {
      const response = await axios.get(
        `wallet/get_company_wallet_recovery_balance/${id}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      );
      setRecoverybalance(response.data.body.totalBalance);
    } catch (err) {
      console.error("Error fetching recovery balance:", err);
    }
  };

  const creditAcceptData = creditLineDetails?.creditAcceptData
    ?.filter((product) => product?._to_wallet_id === id)
    .reduce((acc, product) => {
      return acc + (product?._balance || 0);
    }, 0);

  const creditRecoverData = creditLineDetails?.recoveryData
    ?.filter((product) => product?._to_wallet_id === id)
    .reduce((acc, product) => {
      return acc + (product?._balance || 0);
    }, 0);

  useEffect(() => {
    GetAllCreditDetails();
    GetCreditlinerecoverybalance();
  }, []);

  const renderPurchaseItems = () => {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            p: 1.5,
          }}
        >
          <Grid
            item
            xl={2.5}
            lg={2.5}
            md={2.5}
            sm={2.5}
            xs={2.5}
            align="left"
            sx={{
              ...Styles.textHead,
            }}
          >
            Product Name
          </Grid>
          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            sx={{
              ...Styles.textHead,
            }}
          >
            Type
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            sx={{
              ...Styles.textHead,
            }}
          >
            Quantity
          </Grid>
          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            sx={{
              ...Styles.textHead,
            }}
          >
            Amount
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            sx={{
              ...Styles.textHead,
            }}
          >
            GST
          </Grid>
          {/* <Grid               item xl={2} lg={2} md={2} sm={2} xs={2}
>Credit Requested</Grid> */}
          <Grid
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={{
              ...Styles.textHead,
            }}
          >
            Credit Receiving
          </Grid>
          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            sx={{
              ...Styles.textHead,
            }}
          >
            Credit History
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            sx={{
              ...Styles.textHead,
            }}
          >
            Action
          </Grid>
        </Grid>

        {walletFundDataLoading ? (
          "loading...."
        ) : WalletFundData?.data?.message ===
          "No funds Requests found for the company" ? (
          <Typography
            sx={{
              textAlign: "center",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
              mt: 5,
            }}
          >
            No Data Found
          </Typography>
        ) : (
          WalletFundData?.data?.data?.map((x, i) => {
            return (
              <Grid
                container
                sx={{
                  p: 1.5,
                  borderBottom: "1px solid rgba(245, 245, 245, 1)",
                }}
              >
                <Grid
                  item
                  xl={2.5}
                  lg={2.5}
                  md={2.5}
                  sm={2.5}
                  xs={2.5}
                  align="left"
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{
                      height: "50px",
                      width: "50px",
                    }}
                    src={x?.productId?.ProductImages?.at(0)?.url}
                  />
                  <Box className="product-title-box">
                    <Typography
                      sx={{
                        color: "#000",
                      }}
                    >
                      {x?.productId?.ProductName}
                    </Typography>
                    <span>Product ID : {x?.productId?._id} </span>
                  </Box>{" "}
                </Grid>
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {x?.ProductType}
                </Grid>
                {/* <td align="left" className="product-title-colum"></td> */}
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {x?.productId?.ProductsVariantions?.at(0)?.MaxOrderQuantity}
                </Grid>
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {x?.productId?.ProductsVariantions?.at(0)?.PricePerUnit}
                </Grid>
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {x?.productId?.ProductsVariantions?.at(0)?.GST} %
                </Grid>
                {/* <td className="font-12-400">
                      {x?.amount !== 0 ? (
                        <>
                          <Box sx={{ display: "flex", gap: "5px" }}>
                            <Box
                              component="img"
                              src={bxiToken}
                              sx={{ height: "25px", width: "25px" }}
                            />
                            <Box>{x?.amount}</Box>
                          </Box>
                        </>
                      ) : null}
                    </td> */}
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Box
                    component="img"
                    src={bxiToken}
                    sx={{ height: "20px", width: "20px" }}
                  />
                  <div>
                    <label>
                      <CommaSeparator Price={x?.amount} />
                    </label>
                    <span>&nbsp;</span>
                  </div>
                </Grid>
                {/* <td className="font-12-400">
                      {x?.status === "accepted" ? x?.amount : "--"}
                    </td> */}
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {x?.status}
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    style={{ color: "rgba(68, 95, 210, 1)" }}
                  >
                    <Delete
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                        setIds(x?._id);
                      }}
                    />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    style={{ color: "rgba(68, 95, 210, 1)" }}
                  >
                    <Edit
                      onClick={() => {
                        setIsEditModalOpen(true);
                        setWalletEditData(x);
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })
        )}
        {walletFundDataLoading ? null : WalletFundData?.data?.message ===
          "No funds Requests found for the company" ? null : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              mx: "auto",
            }}
            mt={2}
          >
            <Stack>
              <StyledPagination
                size="medium"
                count={initialPaginationCount}
                color="primary"
                showFirstButton
                showLastButton
                page={currentPage}
                onChange={handlePageChange}
                strokeWidth={currentPage}
              />
            </Stack>
          </Box>
        )}
      </React.Fragment>
    );
  };

  return (
    <div>
      <Box
        className="page-heading-section"
        style={{ justifyContent: "start", gap: "30px" }}
      >
        <Box className="page-heading">
          <h5>Wallet Data</h5>
          <Typography>
            Here you can view your detailed wallet transaction details.
          </Typography>
        </Box>
        {/* <Box className="wallet-cart"> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Table sx={{ minWidth: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow
                  sx={{
                    height: "24px",
                    borderRadius: "5px",
                  }}
                >
                  <TableCell
                    sx={{
                      p: 0.3,
                      width: "200px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Total Usable Balance
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.3,
                      width: "200px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Total Sales
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.3,
                      width: "200px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Total Purchase
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.3,
                      width: "200px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Pending Receivable
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.3,
                      width: "200px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Pending Payable
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0.3,
                      width: "200px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Account Balance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    height: "24px",
                  }}
                >
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Box>
                      {/*Total Usable Balance */}
                      <span>
                        <Box sx={amountstyle}>
                          <span style={textVal}>
                            <CommaSeparator
                              Price={totalBalance?.data?.body?.balance}
                            />
                          </span>
                          <Box
                            component="img"
                            src={bxiToken}
                            sx={{ height: "13px", width: "13px", mt: -0.2 }}
                          />
                        </Box>
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Box>
                      {/* Total Sales */}
                      <span>
                        <Box sx={amountstyle}>
                          <span style={textVal}>
                            <CommaSeparator
                              Price={
                                totalBalance?.data?.body?.total_sold_balance
                              }
                            />
                          </span>
                          <Box
                            component="img"
                            src={bxiToken}
                            sx={{ height: "13px", width: "13px", mt: -0.2 }}
                          />
                        </Box>
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Box>
                      {/* Total Purchase */}
                      <span>
                        <Box sx={amountstyle}>
                          <span style={textVal}>
                            <CommaSeparator
                              Price={
                                totalBalance?.data?.body
                                  ?.total_purchased_balance
                              }
                            />
                          </span>
                          <Box
                            component="img"
                            src={bxiToken}
                            sx={{ height: "13px", width: "13px", mt: -0.2 }}
                          />
                        </Box>
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Box>
                      {/* Pending Receivable */}
                      <span>
                        <Box sx={amountstyle}>
                          <span style={textVal}>
                            <CommaSeparator
                              Price={totalBalance?.data?.body?.sold_On_Hold}
                            />
                          </span>
                          <Box
                            component="img"
                            src={bxiToken}
                            sx={{ height: "13px", width: "13px", mt: -0.2 }}
                          />
                        </Box>
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Box>
                      {/* Pending Payable */}
                      <span>
                        <Box sx={amountstyle}>
                          <span style={textVal}>
                            <CommaSeparator
                              Price={
                                totalBalance?.data?.body?.purchased_On_Hold
                              }
                            />
                          </span>
                          <Box
                            component="img"
                            src={bxiToken}
                            sx={{ height: "13px", width: "13px", mt: -0.2 }}
                          />
                        </Box>
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Box>
                      {/* Account Balance */}
                      <span>
                        <Box sx={amountstyle}>
                          <span style={textVal}>
                            <CommaSeparator
                              Price={totalBalance?.data?.body?._account_balance}
                            />
                          </span>
                          <Box
                            component="img"
                            src={bxiToken}
                            sx={{ height: "13px", width: "13px", mt: -0.2 }}
                          />
                        </Box>
                      </span>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Table sx={{ width: "85%" }} aria-label="customized table">
              <TableHead>
                <TableRow
                  sx={{
                    height: "24px",
                    borderRadius: "5px",
                  }}
                >
                  <TableCell
                    sx={{
                      p: 0,
                      width: "100px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Total Credit Requested
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      width: "100px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Credit Issued
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      width: "100px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Credit Due
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      width: "100px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Credits Recovered
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      width: "100px",
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    Escrow Balance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    height: "24px",
                  }}
                >
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Box>
                      {/* Total Credit Requested */}
                      <span>
                        {credit?.data?.TotalRequestedAmount ===
                        "No Data Found" ? (
                          <Typography
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                              width: "500%",
                              mt: 5,
                            }}
                          >
                            NA
                          </Typography>
                        ) : (
                          <>
                            <Box sx={amountstyle}>
                              <span style={textVal}>
                                <CommaSeparator
                                  Price={credit?.data?.TotalRequestedAmount}
                                />
                              </span>
                              <Box
                                component="img"
                                src={bxiToken}
                                sx={{ height: "13px", width: "13px", mt: -0.2 }}
                              />
                            </Box>
                          </>
                        )}
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    {" "}
                    <Box>
                      {/* Credit Issued */}
                      <span>
                        <Box sx={amountstyle}>
                          <span style={textVal}>
                            {" "}
                            <CommaSeparator Price={creditAcceptData} />
                          </span>
                          <Box
                            component="img"
                            src={bxiToken}
                            sx={{ height: "13px", width: "13px", mt: -0.2 }}
                          />
                        </Box>
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Box>
                      {/* Credit Due */}
                      <span>
                        <Box sx={amountstyle}>
                          <span style={textVal}>
                            <CommaSeparator
                              Price={totalBalance?.data?.body?.creditDue}
                            />
                            {/* <CommaSeparator Price={creditDue} /> */}
                          </span>
                          <Box
                            component="img"
                            src={bxiToken}
                            sx={{ height: "13px", width: "13px", mt: -0.2 }}
                          />
                        </Box>
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    {" "}
                    <Box>
                      {/* Credits Recovered */}
                      <span>
                        <Box sx={amountstyle}>
                          <span style={textVal}>
                            <CommaSeparator
                              Price={
                                Recoverybalance
                                  ? Recoverybalance
                                  : creditAcceptData -
                                    totalBalance?.data?.body?.creditDue
                              }
                            />
                          </span>
                          <Box
                            component="img"
                            src={bxiToken}
                            sx={{ height: "13px", width: "13px", mt: -0.2 }}
                          />
                        </Box>
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Box>
                      {/* Escrow Balance */}
                      <span>
                        <>
                          <Box sx={amountstyle}>
                            {EscrowBalance?.data?.data ===
                            "no escrow pay id found" ? (
                              <span style={textVal}>null</span>
                            ) : (
                              <span style={textVal}>
                                {EscrowBalance?.data?.data?.at(0)?.balance} ₹
                              </span>
                            )}
                          </Box>
                        </>
                      </span>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        <Wallettransaction />
      </Box>
      <CommonDelete
        open={isDeleteModalOpen}
        onClose={handleClose}
        onDelete={() => {
          handleDelete(ids);
        }}
        text="Are you sure you want to delete this wallet item?"
        ButtonLabel="Delete"
        cancelButtonLabel="Cancel"
      />
      <WalletEdit
        key={walletEditData?._id}
        open={isEditModalOpen}
        onClose={handleEditClose}
        onSave={() => {
          handleEditSave(walletEditData);
        }}
        text="Credit Edit"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        // fieldOne="Credit Requested"
        fieldOne="Credit Reqceiving"
        fieldTwo="Credit Receiving"
        // productName={walletEditData?.productId?.ProductName}
        initialProductData={walletEditData}
        refetch={WalletRefetch}
      />
    </div>
  );
};

export default Wallet;

const DeleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const ModalHeaderStyle = {
  color: "#6B7A99",
  fontFamily: "Inter",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  my: 2,
};

const ButtonBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "flex-end",
  gap: 1,
  width: "100%",
  bottom: 0,
  height: "100%",
  mt: 2,
};
const ButtonStyle = {
  fontFamily: "poppins",
  fontSize: 10,
  fontWeight: 500,
  border: "0.5px solid rgba(208, 213, 221, 1)",
  width: "140px",
  textTransform: "none",
  borderRadius: 1.5,
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};

const textVal = {
  color: "#1B212D",
  fontFamily: "Poppins",
  fontSize: 11.273,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
};

const lablestyle = {
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: 11,
  color: "rgba(107, 122, 153, 1)",
};

const amountstyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
